import React, { ReactElement, Ref, useImperativeHandle } from 'react';
import { PulseStepFormHandles, PulseStepFormProps, StepDataItem } from './pulse-step-form-types';
import styles from './pulse-step-form.module.scss';
import get from 'lodash/get';
import { StepIconProps } from '@mui/material/StepIcon';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import clsx from 'clsx';

const STEPPER_ORIENTATION = 'vertical';

function PulseStepIcon(props: StepIconProps): ReactElement {
  const { icon, active, completed } = props;

  return (
    <div
      className={clsx([
        styles['pulse-step-form__step-icon'],
        active && styles['pulse-step-form__step-icon--active'],
        completed && styles['pulse-step-form__step-icon--completed'],
      ])}
    >
      {icon}
    </div>
  );
}

const PulseStepForm = (props: PulseStepFormProps, ref: Ref<PulseStepFormHandles>): ReactElement => {
  const { stepsData, classes, nonLinear, stepperProps, stepProps, stepperLabelProps } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const orientation = stepperProps?.orientation ?? STEPPER_ORIENTATION;

  useImperativeHandle(
    ref,
    (): PulseStepFormHandles => ({
      handleNext: (step: number): void => {
        if (!nonLinear && step === activeStep + 1) {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
      },
      handleBack: (step: number): void => {
        if (!nonLinear && step - 1 === activeStep) {
          setActiveStep(prevActiveStep => prevActiveStep - 1);
        }
      },
    }),
  );

  return (
    <Stepper
      data-testid="pulse-step-form__stepper"
      classes={{
        root: clsx([styles['pulse-step-form__stepper'], 'uk-padding-remove']),
        ...get(classes, 'stepper'),
      }}
      activeStep={activeStep}
      orientation={STEPPER_ORIENTATION}
      connector={<></>}
      nonLinear={nonLinear}
      {...stepperProps}
    >
      {stepsData.map((step: StepDataItem) => (
        <Step
          key={step.label}
          data-testid="pulse-step-form__step"
          classes={{
            root: clsx([styles['pulse-step-form__step'], 'uk-padding-large']),
            ...get(classes, 'step'),
          }}
          {...stepProps}
        >
          <StepLabel
            classes={{
              root: clsx([styles['pulse-step-form__step-label'], 'uk-margin-small-bottom']),
              disabled: styles['pulse-step-form__step-label--disabled'],
              iconContainer: styles['pulse-step-form__icon-container'],
              label: styles['pulse-step-form__label'],
              ...get(classes, 'stepLabel'),
            }}
            StepIconComponent={PulseStepIcon}
            {...stepperLabelProps}
          >
            {step.label}
          </StepLabel>
          {orientation && (
            <StepContent
              classes={{
                root: clsx([styles['pulse-step-form__step-content'], 'uk-padding-remove']),
                ...get(classes, 'stepContent'),
              }}
              TransitionProps={{ in: true }}
            >
              {step.component}
            </StepContent>
          )}
        </Step>
      ))}
    </Stepper>
  );
};

export default React.forwardRef(PulseStepForm);
