import { isTruncated } from 'pulse-commons/helpers';
import { PulseWithMoreProps } from './pulse-with-more-types';
import clsx from 'clsx';
import get from 'lodash/get';
import PulseLabel from '../pulse-label/pulse-label';
import React, { Children, PropsWithChildren, ReactElement, Ref, useEffect, useRef, useState } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import styles from './pulse-with-more.module.scss';
import Tippy, { TippyProps } from '@tippyjs/react/headless';
import compact from 'lodash/compact';
import { ElementPropsWithElementRef } from 'react-scrollbars-custom/dist/types/types';

const isEqual = (prevProps: PulseWithMoreProps, nextProps: PulseWithMoreProps) => {
  return prevProps.children === nextProps.children && prevProps.maxWidth === nextProps.maxWidth;
};

/**
 * @todo
 *
 * 1. Currently, PulseWithMore receives the children and then render the children.
 * A better solution that should be looked into is passing the data to PulseWithMore
 * and then passing the component we want to render.
 */
export const PulseWithMore = (
  props: PropsWithChildren<PulseWithMoreProps>,
  ref: Ref<HTMLDivElement> | null,
): ReactElement => {
  const {
    children,
    classes,
    maxWidth = 200,
    TippyPropsHidden,
    MoreLabelProps,
    HiddenChildProps,
    childrenKey,
    childrenSpacing = styles.pulseLabelSpacing,
    hoverTippy = false,
    hiddenTippyRef,
  } = props;

  const [isMounted, setIsMounted] = useState(false);
  const [isWithMoreTruncated, setIsWithMoreTruncated] = useState(false);
  const [showTippy, setShowTippy] = useState(false);
  /**
   * @const withMoreHiddenEl - reference to the hidden element that is rendered but hidden containing all the elements
   */
  const withMoreHiddenEl: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  /**
   * @const moreLabelEl - reference to the label that shows how many items are hidden
   */
  const moreLabelEl = useRef(null);
  /**
   * @const moreLabelHiddenEl - reference to the label that is rendered like withMoreHiddenEl so we can calculate its width
   */
  const moreLabelHiddenEl: React.MutableRefObject<HTMLSpanElement | null> = useRef(null);

  const childRefs: React.MutableRefObject<HTMLElement[] | null[]> = useRef([]);

  const timeout: React.MutableRefObject<NodeJS.Timeout | null> = useRef(null);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  useEffect(() => {
    timeout.current = setTimeout(() => setIsWithMoreTruncated(isTruncated(withMoreHiddenEl.current)), 1);
  }, [children]);

  /* istanbul ignore next */
  const childrenWithRef = Children.map(children, (element, index) => {
    const elementClasses = get(element, 'props.classes', {});
    return React.cloneElement(element as ReactElement<any>, {
      ref: (r: HTMLElement) => (childRefs.current[index] = r),
      classes: {
        ...elementClasses,
        root: clsx([styles['pulse-with-more__child'], elementClasses?.root]),
      },
    });
  });
  /**
   * We are doing this so we can render the children and then measure their width. childrenWithRef
   * gets rendered inside the hidden div element
   *
   * @todo - Try to draw the elements on a canvas
   */

  /* istanbul ignore next */
  let totalWidthOfRenderedChildren =
    (isWithMoreTruncated && moreLabelHiddenEl.current && moreLabelHiddenEl.current.offsetWidth) || 0;

  const withMoreChildrenArray = React.Children.toArray(children);
  const [childrenHiddenCount, setChildrenHiddenCount] = useState(0);

  const renderedChildrenArray: ReactElement[] = [];
  const hiddenChildrenArray: ReactElement[] = [];
  for (const [index, element] of withMoreChildrenArray.entries()) {
    const elementClasses = get(element, 'props.classes', {}) as any;
    /**
     * If the parent root is truncated,
     * then calculate how many children
     * can be displayed
     */

    /* istanbul ignore next */
    if (isWithMoreTruncated) {
      /** Get the width of the child element */
      const elementWidth =
        compact(childRefs.current).find(childRef => {
          return childRef.dataset[childrenKey] === (element as ReactElement).props[childrenKey];
        })?.offsetWidth || 0;
      /**
       * Is the width of the already rendered children plus the new child element less that the max width
       * If it is then render it
       */
      if (totalWidthOfRenderedChildren + elementWidth + (index + 1) * parseInt(childrenSpacing) < maxWidth) {
        totalWidthOfRenderedChildren += elementWidth;
        renderedChildrenArray.push(
          React.cloneElement(element as ReactElement<any>, {
            classes: {
              ...elementClasses,
              root: clsx([styles['pulse-with-more__child'], elementClasses?.root]),
            },
          }),
        );
      } else {
        /**
         * We want to render the labels in the order that they are returned. So we need to push whatever cannot be rendered
         * into hiddenChildrenArray and break out of the loop. If we do not break out of the loop, then if a label can fit
         * in the available space it will and the labels will not be in the order returned from the server.
         *
         * @todo Enquire if the order is important?
         */
        withMoreChildrenArray.slice(index, React.Children.count(children)).map(child => {
          hiddenChildrenArray.push(
            React.cloneElement(child as ReactElement<any>, {
              classes: {
                ...elementClasses,
                root: clsx([
                  styles['pulse-with-more__child--hidden'],
                  elementClasses?.root,
                  HiddenChildProps?.classes?.root,
                ]),
              },
              ...HiddenChildProps,
            }),
          );
        });
        break;
      }
    } else {
      renderedChildrenArray.push(
        React.cloneElement(element as ReactElement<any>, {
          classes: {
            ...elementClasses,
            root: clsx([styles['pulse-with-more__child'], elementClasses?.root]),
          },
        }),
      );
    }
  }

  useEffect(() => {
    isMounted && setChildrenHiddenCount(hiddenChildrenArray.length);
  }, [hiddenChildrenArray.length]);

  const trackYRenderer = (props: ElementPropsWithElementRef<HTMLDivElement>) => {
    const { elementRef, style, ...restProps } = props;
    const customStyle = {
      ...style,
      width: 4,
    };
    return <span {...restProps} ref={elementRef} style={customStyle} className="trackY " />;
  };

  const handleCloseTippyContent = () => {
    setShowTippy(false);
  };

  const handleShowTippy = () => {
    setShowTippy(!showTippy);
  };

  /**
   * Render the content for the Tippy that displays the hidden children
   *
   * @param attrs
   */
  const renderHiddenTippyContent: TippyProps['render'] = attrs => (
    <div
      ref={hiddenTippyRef}
      className={clsx(styles['pulse-with-more__hidden-root'], classes?.hidden?.root)}
      tabIndex={-1}
      {...attrs}
    >
      <div
        style={{ width: 300, height: 100 }}
        className={clsx(styles['pulse-with-more__hidden-content'], classes?.hidden?.content)}
      >
        <Scrollbar
          removeTracksWhenNotUsed
          trackYProps={{
            renderer: trackYRenderer,
          }}
        >
          {hiddenChildrenArray}
        </Scrollbar>
      </div>
    </div>
  );

  const pulseWithMoreRootClasses = clsx('pulse-with-more__append-to', styles['pulse-with-more__root'], classes?.root);
  let tippyProps: TippyProps = {
    interactive: true,
    placement: 'bottom-end',
    render: renderHiddenTippyContent,
    appendTo: document.querySelector(`.${styles['pulse-with-more__child--more-root']}`) || undefined,
    arrow: true,
    ...TippyPropsHidden,
  };
  if (!hoverTippy) {
    tippyProps = {
      visible: showTippy,
      onClickOutside: handleCloseTippyContent,
      onHide: handleCloseTippyContent,
      ...tippyProps,
    };
  }
  /* istanbul ignore next */
  return (
    <>
      {/**
       * This is a hidden div to render so we can
       * measure the size of elements being rendered
       */}
      <div style={{ visibility: 'hidden', position: 'absolute' }}>
        <div
          className={clsx([styles['pulse-with-more__root--hidden']])}
          ref={withMoreHiddenEl}
          style={{
            maxWidth,
          }}
        >
          {childrenWithRef}
        </div>
        <PulseLabel
          classes={{
            root: [styles['pulse-with-more__child'], styles['pulse-with-more__child--more']],
          }}
          ref={moreLabelHiddenEl}
          label={`+${childrenHiddenCount.toString()}`}
        />
      </div>
      <div
        data-testid="pulse-with-more__root"
        className={pulseWithMoreRootClasses}
        ref={ref}
        style={{
          maxWidth,
        }}
      >
        {/**
         * renderedChildrenArray is the list of children
         * elements that fits within the maxWidth
         */}
        {renderedChildrenArray}
        {/**
         * If the number of children is truncated,
         * then show a tooltip with the children inside
         */}
        {Boolean(childrenHiddenCount) && (
          <Tippy {...tippyProps}>
            <div
              className={clsx(styles['pulse-with-more__child--more-root'], classes?.more?.root)}
              onClick={handleShowTippy}
            >
              <PulseLabel
                classes={{
                  root: clsx([
                    classes?.more?.label?.root,
                    styles['pulse-with-more__child--more'],
                    showTippy ? clsx(styles['pulse-with-more__child--active'], classes?.more?.label?.active) : '',
                  ]),
                }}
                ref={moreLabelEl}
                label={`+${childrenHiddenCount.toString()}`}
                {...MoreLabelProps}
              />
            </div>
          </Tippy>
        )}
      </div>
    </>
  );
};

export default React.memo<PulseWithMoreProps>(
  React.forwardRef<HTMLDivElement, PulseWithMoreProps>(PulseWithMore),
  isEqual,
);
