export type PulseClassName = string | string[];
import { Placement } from 'tippy.js';

export enum Sizes {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

export enum Colors {
  disabled = 'pulse-disabled',
  default = 'pulse-default',
  warning = 'pulse-warning',
  success = 'pulse-success',
  danger = 'pulse-danger',
  primary = 'pulse-primary',
  secondary = 'pulse-secondary',
}

export enum ButtonVariants {
  outlined = 'pulse-button--outlined',
  contained = 'pulse-button--contained',
  text = 'pulse-button--text',
}

export enum ButtonSizes {
  sm = 'pulse-button--sm',
  md = 'pulse-button--md',
  lg = 'pulse-button--lg',
}

export enum IconSizes {
  sm = 'pulse-icon--sm',
  md = 'pulse-icon--md',
  lg = 'pulse-icon--lg',
}

export enum LabelVariants {
  outlined = 'pulse-label--outlined',
  contained = 'pulse-label--contained',
  text = 'pulse-label--text',
}

export enum LabelSizes {
  sm = 'pulse-label--sm',
  md = 'pulse-label--md',
  lg = 'pulse-label--lg',
}

export enum AvatarSizes {
  sm = 'pulse-avatar--sm',
  md = 'pulse-avatar--md',
  lg = 'pulse-avatar--lg',
}

export const Statuses = {
  online: {
    name: 'online',
    value: 'status__bullet--online',
  },
  away: {
    name: 'away',
    value: 'status__bullet--away',
  },
  busy: {
    name: 'busy',
    value: 'status__bullet--busy',
  },
};

export const officeFileExtension: Record<string, string> = {
  'vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
  'vnd.ms-excel': 'xls',
  'vnd.ms-powerpoint': 'ppt',
  msword: 'doc',
};

export enum RequestStatus {
  pending = 'pending',
  success = 'success',
  error = 'error',
}

export enum PulseTabsTheme {
  dark = 'dark',
  light = 'light',
}

export interface TippyPropsRenderAttrs {
  'data-placement': Placement;
  'data-reference-hidden'?: string;
  'data-escaped'?: string;
}

export enum ActionButtonPlacement {
  RIGHT = 'right',
  LEFT = 'left',
}
