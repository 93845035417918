import clsx from 'clsx';
import React, { FC } from 'react';
import { PulseListItemButtonProps } from './pulse-list-item-button-types';
import styles from './pulse-list-item-button.module.scss';
import ListItemButton from '@mui/material/ListItemButton';

export const PulseListItemButton: FC<PulseListItemButtonProps> = props => {
  const { children, classes, ...restProps } = props;

  return (
    <ListItemButton
      data-testid="pulseListItemButton"
      {...restProps}
      disableRipple
      classes={{
        ...classes,
        root: clsx(styles.pulseListItemButton__root, classes?.root),
      }}
    >
      {children}
    </ListItemButton>
  );
};

export default PulseListItemButton;
