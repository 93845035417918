import React, { ReactElement } from 'react';
import Chip from '@mui/material/Chip';
import PulseTooltip from 'components/pulse-tooltip/pulse-tooltip';
import { PulseChipProps } from './pulse-chip-types';
import styles from './pulse-chip.module.scss';
import clsx from 'clsx';

export const PulseChip = (props: PulseChipProps): ReactElement => {
  const { classes, color, fontColor, fontSize, maxWidth = 200, PulseTooltipProps, ...restProps } = props;

  const isOutlined = restProps.variant === 'outlined';

  return (
    <PulseTooltip
      positionTooltip="top"
      {...PulseTooltipProps}
      contentTooltip={PulseTooltipProps?.contentTooltip || `${restProps.label}`}
    >
      <Chip
        classes={{
          ...classes,
          root: clsx(styles['pulse-chip__root'], classes?.root),
          label: clsx(styles['pulse-chip__label'], classes?.label),
        }}
        color="primary"
        data-testid="pulse-chip__root"
        {...restProps}
        style={{
          backgroundColor: !isOutlined ? color : undefined,
          border: isOutlined ? `1px solid ${color}` : undefined,
          color: fontColor,
          fontSize,
          maxWidth,
        }}
      />
    </PulseTooltip>
  );
};

export default PulseChip;
