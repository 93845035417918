import React, { FC, ReactElement, Ref } from 'react';
import bookingStatusStyles from './booking-status.module.scss';
import PulseSelectBase from 'components/pulse-select/base/pulse-select-base';
import clsx from 'clsx';
import { PulseSelectPropTypes } from 'components/pulse-select/base/pulse-select-base-types';
import PulseSelectOption, { PulseSelectOptionProps } from 'components/pulse-select/base/components/pulse-select-option';

const BookingStatusSelectOption = React.forwardRef(
  (props: PulseSelectOptionProps, ref: Ref<HTMLDivElement>): ReactElement => {
    return (
      <PulseSelectOption
        innerRef={ref}
        {...props}
        classes={{
          selected: bookingStatusStyles['select-option__selected'],
        }}
        PulseLabelProps={{
          classes: {
            root: bookingStatusStyles['booking-status__option'],
            labelText: bookingStatusStyles['booking-status__labelText'],
          },
        }}
      />
    );
  },
);

const BookingStatus = (props: PulseSelectPropTypes): ReactElement => {
  const { classes, ...restProps } = props;
  return (
    <PulseSelectBase
      dataStructure={null}
      inheritedMaxWidth={250}
      classes={{
        root: clsx(bookingStatusStyles['edit-form__select'], classes?.root),
        label: clsx(classes?.label),
        select: clsx(bookingStatusStyles['form-select__select'], classes?.select),
        menu: {
          menuCtn: bookingStatusStyles['form-select__menu-ctn'],
        },
        placeholder: bookingStatusStyles['booking-status__placeholder'],
        selectContainer: clsx(bookingStatusStyles['form-select__select-container'], classes?.selectContainer),
      }}
      isClearable={false}
      components={{
        Option: BookingStatusSelectOption as FC<any>,
      }}
      {...restProps}
    />
  );
};

export default BookingStatus;
