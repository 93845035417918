import React, { ReactElement, Ref } from 'react';
import { PulseInputProps } from './pulse-input-types';
import InputBase from '@mui/material/InputBase';
import styles from './pulse-input.module.scss';
import clsx from 'clsx';

export const PulseInput = React.forwardRef(
  (props: PulseInputProps, ref: Ref<HTMLDivElement>): ReactElement => {
    const { label = '', classes, InputBaseProps, required = false, error, isInvalid = false, labelTag } = props;

    return (
      <div className={clsx(styles['pulse-input__root'], classes?.root)} data-testid={'pulse-input__ctn'}>
        {label && (
          <span
            className={clsx(
              styles['input-header__label'],
              InputBaseProps?.disabled && styles['input-header__label--disabled'],
              classes?.label,
            )}
          >
            <span>
              {label}
              {labelTag && (
                <span
                  data-testid="pulse-input-label"
                  className={clsx(styles['input-header__label-tag'], labelTag?.newTag)}
                >
                  {labelTag.content}
                </span>
              )}
            </span>
            {required && (
              <span
                className={clsx(
                  styles['pulse-input__required-label'],
                  isInvalid && styles['pulse-input__required-label--error'],
                  classes?.error,
                )}
              >
                *Required
              </span>
            )}
          </span>
        )}

        <InputBase
          autoComplete={'off'}
          fullWidth
          placeholder={'Input'}
          {...InputBaseProps}
          ref={ref}
          classes={{
            ...InputBaseProps?.classes,
            root: clsx(styles['pulse-input__inputBaseRoot'], InputBaseProps?.classes?.root, classes?.root),
            input: clsx(
              styles['pulse-input__input'],
              InputBaseProps?.multiline && styles['pulse-input__input-multiline'],
              InputBaseProps?.disabled && styles['pulse-input__input--disabled'],
              InputBaseProps?.classes?.input,
              classes?.input,
              isInvalid && styles['pulse-input__input--error'],
            ),
          }}
        />
        {error && (
          <span data-testid={'pulse-input__error'} className={clsx(styles['pulse-input__error'], classes?.error)}>
            {error}
          </span>
        )}
      </div>
    );
  },
);

export default PulseInput;
